import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { CheckCircleIcon } from "@heroicons/react/solid";
import Layout from "../components/layout";
import useProduct from "../components/utils/useProduct";

export const query = graphql`
  query SanityMan($id: String!) {
    sanityMan(id: { eq: $id }) {
      name
      id
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData
        }
      }
    }
  }
`;

export default function SanityMan({ data }) {
  const { order, addToOrder } = useProduct();
  return (
    <Layout>
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="sm:flex items-center justify-around">
            <div className="py-10 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
              <div className="overflow-hidden baoverflow-hidden bg-white p-5 rounded-lg">
                <GatsbyImage
                  className="w-full h-full object-center object-cover"
                  image={data.sanityMan.image.asset.gatsbyImageData}
                  alt={data.sanityMan.name}
                />
              </div>
            </div>

            <div className="pb-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
              <h2 className="text-1xl text-center font-bold text-gray-900 sm:text-3xl">
                {data.sanityMan.name}
              </h2>
              <div className="mt-5">
                {order.filter((e) => e.id === data.sanityMan.id).length > 0 ? (
                  <button
                    className="inline-flex w-full bg-gray-100 items-center pointer-events-none py-3 px-8 text-sm border border-transparent shadow-sm font-medium rounded-md"
                    type="button"
                  >
                    <CheckCircleIcon
                      className="-ml-0.5 mr-2 h-4 w-4 text-green-400"
                      aria-hidden="true"
                    />
                    Добавлено
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      addToOrder({
                        id: data.sanityMan.id,
                        name: data.sanityMan.name,
                      })
                    }
                    type="button"
                    className="w-full bg-yellow-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  >
                    Добавить
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
